<template>
    <div class="table-form" :class="{'detail': isDetail}">
        <div class="title" @click="titleClick">{{form.name}}</div>
        <table border="1">
            <tr>
                <td>离退类型</td>
                <td>
                    <el-select v-model="form.type" :disabled="isDetail">
                        <el-option v-for="item in EnumList.changeStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </td>
                <td>离退日期</td>
                <td>
                    <el-date-picker v-model="form.adjustTime" type="date" :readonly="isDetail"></el-date-picker>
                </td>
            </tr>
            <tr>
                <td>{{$replaceText('现任部门')}}</td>
                <td>
					<!-- <el-input v-model='positionText' disabled></el-input> -->
                    <el-cascader v-model="currentOrgIdList" :options="orgList" :props="{ checkStrictly: true, label: 'name',value: 'id'}" disabled></el-cascader>
                </td>
                <td>现任职务</td>
                <td>
                    <el-select :value="form.currentPositionIds && form.currentPositionIds.split(',')" multiple disabled>
                        <el-option v-for="item in jobList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>离退原因</td>
                <td colspan="3" v-html="form.adjustReason && form.adjustReason.replaceAll('\n','<br />')"></td>
            </tr>
            <tr>
                <td>呈报单位</td>
                <td colspan="3" v-html="form.reportUnit && form.reportUnit.replaceAll('\n','<br />')"></td>
            </tr>
            <tr>
                <td>审批机关意见</td>
                <td colspan="3" v-html="form.approvalAuthorityOpinion && form.approvalAuthorityOpinion.replaceAll('\n','<br />')"></td>
            </tr>
            <tr>
                <td>行政机关任免意见</td>
                <td colspan="3" v-html="form.positionChangeOpinion && form.positionChangeOpinion.replaceAll('\n','<br />')"></td>
            </tr>
            <tr>
                <td>备注</td>
                <td colspan="3" v-html="form.notes && form.notes.replaceAll('\n','<br />')"></td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getJobList , getOrgList , userDetail } from '@/api/hrAPI.js';
import { getIdListInTree, getCourtByOrgId } from '@/utils/handle.js';

export default {
    props: {
        isDetail: {
            type: Boolean,
            default: true
        },
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        detailInfo: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    inject: ['detailForm'],
    data() {
        return {
            jobList: [],
            orgList: [],
            rules: {

            },
            currentOrgIdList: [],
			// positionText: ''
        };
    },
    created(){
        getJobList().then(res => {
            this.jobList = res.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
        })
        this.filterOrgList()
    },
    watch: {
        'detailInfo.id': {
            handler(){
                this.filterOrgList()
            },
        },
    },
    methods: { 
        titleClick(){
            userDetail({ id: this.detailInfo.userId }).then(res => {
                this.detailForm(res);
            })
        },
        filterOrgList() {
			getOrgList().then(orgs => {
				const { currentOrgId } = this.form
				const res = {courts: [], pid: ''}
				getCourtByOrgId(orgs, currentOrgId, res)
				// this.positionText = res.courts.map(court => court.name).join('/')
				const court = res.courts.find(court => court.pid === res.pid || court.id === res.pid)
				this.orgList = court ? [court] : []

				this.currentOrgIdList = getIdListInTree(this.orgList ,this.form.currentOrgId);
        	})
		}
    },
};
</script>

<style lang="less" scoped>
.title{
    color: #3291F8;
    justify-content: center;
    cursor: pointer;
}
td:nth-child(1),td:nth-child(3){
    width: 15%;
    background-color: #F0F2F5;
}
</style>