<template>
    <div class="examine">
        <div class="tabs-container"><Tabs v-model="params.type" :data="typeList" @change="typeChange"></Tabs></div>
        
        <div class="content-container">
            <div class="search-container">
                <Search :paramsHeaders="searchHeaders" :params="params" :getTableData="getTableData" ignoreKeys="type">
                    <template slot="date-picker">
                        <el-date-picker
                            style="width: 350px;"
                            size="small"
                            v-model="startTimeValue"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            @change="startTimeValueChange"
                            clearable>
                        </el-date-picker>
                    </template>
                    <template slot="org">
                        <MyCascader v-model="params.orgId" :options="orgList" size="small" :props="cascaderProps" clearable></MyCascader>
                    </template>
                </Search>
            </div>
            <div class="table-container">
                <CommonTable :headers="headers" :tableData="tableData" :selection="true" :selectList.sync="selectList" :loading="loading" :hasIndex="true" :maxHeight="620">
                    <template slot="sex" slot-scope="{ row }" >
                        {{EnumType.sex[row.sex]}}
                    </template>
                    <template slot="education" slot-scope="{ row }">
                        {{EnumType.education[row.qualification]}}
                    </template>
                    <template slot="postitionChange" slot-scope="{ row }">
                        {{EnumType.changeStatus[row.postitionChangeType]}}
                    </template>
                    <template slot="examineStatus" slot-scope="{ row }">
                        <div :style="{'color':EnumType.examineStatusColor[row.examineStatus]}">{{EnumType.examineStatus[row.examineStatus]}}</div>
                    </template>
                    <template slot="examineResult" slot-scope="{ row }">
                        <div :style="{'color':EnumType.examineResultColor[row.examineResult]}">{{EnumType.examineResult[row.examineResult]}}</div>
                    </template>
                    <template slot="operate" slot-scope="{ row }">
                        <div class="operate">
                            <el-button type="text" size="small" @click="detailClick(row)">查看</el-button>
                        </div>
                    </template>
                </CommonTable>
            </div>
            <div class="content-footer">
                <el-button size="small" type="danger" @click="delExamine" :disabled="selectList.length === 0" v-permission="['/hrms/user/change/remove']">删除</el-button>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total,sizes,prev, pager, next"
                        :current-page.sync="params.current"
                        :page-sizes="[10, 30, 50, 100]"
                        :page-size.sync="params.size"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        
        <el-dialog :visible.sync="openDetail" width="80%" v-if="openDetail" :close-on-click-modal="false">
            <template slot="title">
                <FlowHeader :detailInfo="detailInfo"></FlowHeader>
            </template>
            <div class="detail-container">
                <Detail :detailInfo="detailInfo.extra.formData"
                    :orgList="orgList"
                    :jobList="jobList"
                    v-if="params.type === 'REGISTER' || params.type === 'CHANGE'"
                ></Detail>
                <!-- ADJUST_POST(调职) -->
                <Transfer v-if="params.type === 'ADJUST_POST'" :title="detailInfo.name" :detailInfo="detailInfo" :form="detailInfo.extra.formData"></Transfer>
                <!-- LEAVE(离退) -->
                <Leave v-if="params.type === 'LEAVE'" :title="detailInfo.name" :detailInfo="detailInfo" :form="detailInfo.extra.formData"></Leave>

                <Examine class="mt15" :tableData="detailInfo.historyWorkFlows"></Examine>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import { flowUserList , getJobList , getOrgList , getFormData , delFormData } from '@/api/hrAPI.js';
import Tabs from '@/components/tabs';
import { examine } from '@/components/table/data.js';
import { batchDel } from '@/utils/handle.js';
import Detail from '@/components/detail';
import Transfer from '@/components/table/transfer';
import Leave from '@/components/table/leave';
import Examine from '@/components/table/examine';

export default {
    components: {
        Tabs,
        Detail,
        Examine,
        Transfer,
        Leave
    },
    data(){
        return {
            examineHeaders: examine,
            headers: [
                {
                    label: '姓名',
                    value: 'name',
                },
                {
                    label: '性别',
                    slot: 'sex',
                    value: 'sex'
                },
                {
                    label: '年龄',
                    value: 'age',
                },
                {
                    label: '学历',
                    value: 'qualification',
                    slot: 'education',
                },
                {
                    label: '审批状态',
                    value: 'examineStatus',
                    slot: 'examineStatus'
                },
                {
                    label: '审批结果',
                    value: 'examineResult',
                    slot: 'examineResult'
                },
                {
                    label: this.$getCurrentLib() ? '部门' : '单位',
                    value: 'orgName',
                },
                {
                    label: this.$getCurrentLib() ? '现任职务' : '职务',
                    value: 'currentPositionNames',
                },
                {
                    label: '创建人',
                    value: 'creatorName',
                },
                {
                    label: '创建时间',
                    value: 'createTime',
                },
                {
                    label: '操作',
                    slot: 'operate',
                },
            ],
            tableData: [],
            params: {
                current: 1,
                size: 30,
                type: 'REGISTER',
                startTime: '',
                endTime: '',
                examineStatus: '',
                examineResult: '',
                orgId: '',
                userName: '',
            },
            startTimeValue: [],     //  时间绑定值
            total: 0,
            searchHeaders: [
                {
                    label: '创建时间',
                    value: 'startTime',
                    slot: 'date-picker'
                },
                {
                    label: '审批状态',
                    value: 'examineStatus',
                    type: 'select',
                    typeList: this.EnumList.examineStatusList
                },
                {
                    label: '审批结果',
                    value: 'examineResult',
                    type: 'select',
                    typeList: this.EnumList.examineResultList
                },
                {
                    label: this.$getCurrentLib() ? '部门' : '单位',
                    value: 'orgId',
                    slot: 'org'
                },
                {
                    label: '职务',
                    value: 'positionId',
                    type: 'select',
                    typeList: [],
                },
                {
                    label: '姓名',
                    value: 'userName'
                },
            ],
            cascaderProps: {
                label: 'name',
                value: 'id',
                checkStrictly: true
            },
            selectList: [],
            jobList: [],
            orgList: [],
            loading: false,
            openDetail: false,
            detailInfo: null,
        }
    },
    computed: {
        typeList(){
            return this.EnumList.applyStatusList;
        }
    },
    watch: {
        'params.startTime': {
            handler(val){
                if(!val){
                    this.startTimeValue = [];
                }
            }
        },
    },
    created(){
        this.getTableData();
        getJobList().then(res => { 
            //  搜索框的下拉列表渲染
            // 职务列表
            this.jobList = this.searchHeaders.find(item => item.value === 'positionId').typeList = res.map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            });
        })
        getOrgList().then(res => {
            // 列表数据
            this.orgList = res;
        })
    },
    beforeUpdate(){
        
    },
    methods: {
		// shouldReplace(key) {
		// 	let positionChange = ''
		// 	if(!this.$getCurrentLib()) {
		// 		positionChange = this.$replaceText(this.EnumType.changeStatus[key])
		// 	} else {
		// 		positionChange = this.EnumType.changeStatus[key]
		// 	}

		// 	return positionChange
		// },
        typeChange(type){
            this.params.current = 1;
            this.params.size = 30;
            this.getTableData();
            const filter = ['sex','age','qualification','postitionChangeType','postitionChangeDate'];
            batchDel(this.headers,item => filter.includes(item.value));
            let addHeaders = [];
            if(type === 'ADJUST_POST' || type === 'LEAVE'){
                if(type === 'ADJUST_POST'){
                    addHeaders = [
                        {
                            label: '调职类型',
                            value: 'postitionChangeType',
                            slot: 'postitionChange'
                        },
                        {
                            label: '调职日期',
                            value: 'postitionChangeDate'
                        },
                    ]
                }else{
                    addHeaders = [
                        {
                            label: '离退类型',
                            value: 'postitionChangeType',
                            slot: 'postitionChange'
                        },
                        {
                            label: '离退日期',
                            value: 'postitionChangeDate'
                        },
                    ]
                }
            }else{
                addHeaders = [
                    {
                        label: '性别',
                        slot: 'sex',
                        value: 'sex'
                    },
                    {
                        label: '年龄',
                        value: 'age',
                    },
                    {
                        label: '学历',
                        value: 'qualification',
                        slot: 'education',
                    },
                ]
            }
            this.headers.splice(1,0,...addHeaders)
        },
        getTableData(){
			this.loading = true;
            flowUserList(this.params).then(res => {
                this.tableData = res.records;
                this.total = res.total;
            }).finally(() => {
                this.loading = false;
            })
        },
        startTimeValueChange(val){
            if(val){
                this.params.startTime = val[0];
                this.params.endTime = val[1];
            }else{
                this.params.startTime = '';
                this.params.endTime = '';
            }
        },
        resetParams(){
            this.params.startTime = '';
            this.params.examineStatus = '';
            this.params.examineResult = '';
            this.params.orgId = '';
            this.params.userName = '';
            this.getTableData();
        },
        delExamine1(){
            const ids = this.selectList.map(item => item.id);
            // console.log(ids);
            this.$confirm('确认要删除记录吗 ？删除后，记录将无法恢复。', '确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				const path = '/hrms/user/change/remove'
				const names = this.selectList.map(item => item.name)
				console.log(this.EnumType.applyStatus[this.params.type])
				const note = `删除${names.join(',')}的${this.EnumType.applyStatus[this.params.type] || ''}信息`
				this.$store.commit('status/SET_LOG', {path, note})
                delFormData({ ids: ids.join(',') }).then(res => {
                    this.$message.success('删除成功');
                    this.getTableData();
                })
            }).catch(() => {
                
            });
        },
		async delExamine(){
			await this.$confirm('确认要删除记录吗 ？删除后，记录将无法恢复。', '确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
			for(const user of this.selectList) {
				const {id, name} = user
				const path = '/hrms/user/change/remove'
		  		const note = `删除${name}的${this.EnumType.applyStatus[this.params.type] || ''}信息`
				this.$store.commit('status/SET_LOG', {path, note})
				await delFormData({ ids: id })
			}
            this.$message.success('删除成功');
			this.getTableData();
        },
        async detailClick(row){
            const res = await getFormData({ id: row.id });
            this.detailInfo = res;
            this.openDetail = true;
        },
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.examine{
    padding: 20px;
    // background-color: #fff;
    .tabs-container{
        margin-bottom: 16px;
    }
    .content-container{
        height: calc(100% - 68PX);
        padding: 20px;
        box-sizing: border-box;
        background-color: #fff;
        position: relative;
        .search-container{
            display: flex;
            font-size: 14px;
            .search-item{
                margin-right: 20px;
                display: flex;
                align-items: center;
                .label{
                    margin-right: 10px;
                    color: #101010;
                }
                .content{
                    
                }
            }
        }
        .table-container{
            margin-top: 16px;
        }
        .content-footer{
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 20px;
            width: calc(100% - 40px);
            display: flex;
            justify-content: space-between;
        }
        

    }
}
.detail-container{
    height: 560px;
    overflow: auto;
}

</style>