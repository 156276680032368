<template>
    <div>
        <ColumnTable title="审批流程" :headers="headers" :tableData="filterTableData">
            <template slot="opinion" slot-scope="{ form }">
                <div v-html="form.opinion"></div>
            </template>
        </ColumnTable>
    </div>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            headers: [
                {
                    label: '操作时间',
                    value: 'endTime',
                    width: '20%'
                },
                {
                    label: '操作人员',
                    value: 'userName',
                    width: '10%'
                },
                {
                    label: '审批节点',
                    value: 'examineNode',
                    width: '10%'
                },
                {
                    label: '执行结果',
                    value: 'result',
                    width: '10%'
                },
                {
                    label: '审批意见',
                    value: 'opinion',
                    slot: 'opinion'
                },
            ],
        }
    },
    computed: {
        filterTableData(){
            return this.tableData.map(data => {
                return {
                    ...data,
                    opinion: data.opinion && data.opinion.replaceAll('\n','<br />')
                }
            })
        }
    },
    methods: {
        
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>

</style>