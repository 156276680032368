var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-form",class:{'detail': _vm.isDetail}},[_c('div',{staticClass:"title",on:{"click":_vm.titleClick}},[_vm._v(_vm._s(_vm.form.name))]),_c('table',{attrs:{"border":"1"}},[_c('tr',[_c('td',[_vm._v("调职类型")]),_c('td',[_c('el-select',{attrs:{"disabled":_vm.isDetail},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.EnumList.changeStatusList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('td',[_vm._v("调职日期")]),_c('td',[_c('el-date-picker',{attrs:{"type":"date","readonly":_vm.isDetail},model:{value:(_vm.form.adjustTime),callback:function ($$v) {_vm.$set(_vm.form, "adjustTime", $$v)},expression:"form.adjustTime"}})],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$replaceText('原任部门')))]),_c('td',[_c('el-cascader',{attrs:{"options":_vm.orgList,"props":{
                        checkStrictly: true,
                        label: 'name',
                        value: 'id'
                    },"disabled":""},model:{value:(_vm.orgIdList),callback:function ($$v) {_vm.orgIdList=$$v},expression:"orgIdList"}})],1),_c('td',[_vm._v("原任职务")]),_c('td',[_c('el-select',{attrs:{"value":_vm.form.originalPositionIds && _vm.form.originalPositionIds.split(','),"multiple":"","disabled":""}},_vm._l((_vm.jobList),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$replaceText('现任部门')))]),_c('td',[_c('el-cascader',{attrs:{"options":_vm.orgList,"props":{
                        checkStrictly: true,
                        label: 'name',
                        value: 'id'
                    },"disabled":""},model:{value:(_vm.currentOrgIdList),callback:function ($$v) {_vm.currentOrgIdList=$$v},expression:"currentOrgIdList"}})],1),_c('td',[_vm._v("现任职务")]),_c('td',[_c('el-select',{attrs:{"value":_vm.form.currentPositionIds && _vm.form.currentPositionIds.split(','),"multiple":"","disabled":""},on:{"input":function (val) { return _vm.form.currentPositionIds = val.join(','); }}},_vm._l((_vm.jobList),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)],1)]),_c('tr',[_c('td',[_vm._v("调动原因")]),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.form.adjustReason && _vm.form.adjustReason.replaceAll('\n','<br />'))}})]),_c('tr',[_c('td',[_vm._v("呈报单位")]),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.form.reportUnit && _vm.form.reportUnit.replaceAll('\n','<br />'))}})]),_c('tr',[_c('td',[_vm._v("审批机关意见")]),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.form.approvalAuthorityOpinion && _vm.form.approvalAuthorityOpinion.replaceAll('\n','<br />'))}})]),_c('tr',[_c('td',[_vm._v("行政机关任免意见")]),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.form.positionChangeOpinion && _vm.form.positionChangeOpinion.replaceAll('\n','<br />'))}})]),_c('tr',[_c('td',[_vm._v("备注")]),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.form.notes && _vm.form.notes.replaceAll('\n','<br />'))}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }