<template>
    <div class="tabs">
        <div class="tab-item" :class="{'active': value === item.value}" 
            v-for="item in data" 
            :key="item.value" 
            @click="change(item.value)"
        >{{item.label}}</div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default(){
                return [];
            }
        },
        value: String
    },
    methods: {
        change(val){
            this.$emit('input',val);
            this.$emit('change',val);
        }
    },
}
</script>

<style lang="less" scoped>
.tabs{
    border: 1px solid #CCD2E0;
    background-color: #F5F5F5;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    .tab-item{
        height: 32px;
        line-height: 32px;
        padding: 0 25px;
        cursor: pointer;
    }
    .tab-item.active{
        color: #008B61;
        background-color: #fff;

    }
}
</style>